<template>
  <v-container>
    <div>
      <navbar title="تغییر رمزعبور" />
      <success />
      <error />
      <v-main class="pt-15">
        <v-form @submit.prevent="change_password" ref="change_password">
          <v-card class="card flat-card" color="surface">
            <v-card-text>
              <v-text-field
                label="رمز عبور قبلی"
                v-model="passwords.old_password"
                :rules="[rules.required, rules.count, rules.count_password]"
                maxlength="21"
                :type="showPassword_old ? 'text' : 'password'"
                prepend-icon="mdi-lock-outline"
                :append-icon="showPassword_old ? 'mdi-eye' : 'mdi-eye-off'"
                @click:append="showPassword_old = !showPassword_old"
              />
              <v-text-field
                v-model="passwords.new_password"
                label="رمز عبور جدید"
                :rules="[rules.required, rules.count, rules.count_password]"
                maxlength="21"
                :type="showPassword_new ? 'text' : 'password'"
                prepend-icon="mdi-lock-check-outline"
                :append-icon="showPassword_new ? 'mdi-eye' : 'mdi-eye-off'"
                @click:append="showPassword_new = !showPassword_new"
              />
            </v-card-text>
            <v-card-actions>
              <v-spacer></v-spacer>
              <v-btn
                @click="check"
                type="submit"
                class="button primaryActions onPrimaryActions--text"
                :class="{ 'is-loading': loading_submit }"
                :disabled="loading_submit"
                >ذخیره تغییرات <loading v-if="loading_submit" class="loading"
              /></v-btn>
            </v-card-actions>
          </v-card>
        </v-form>
      </v-main>
    </div>
  </v-container>
</template>
<style lang="scss" scoped>
.is-loading {
  color: transparent;
}
.loading {
  font-size: 8px;
  position: absolute;
  right: 50%;
  bottom: 50%;
  transform: translate(50%, 50%);
}
</style>
<script>
import navbar from "@/components/navbar/appBar";
import loading from "@/components/tools/loading";
import { change_password } from "./models/user";
import error from "@/components/alerts/error.alert";
import success from "@/components/alerts/success.alert";
import { alertMessage } from "@/assets/messageAlert";
export default {
  components: {
    error,
    success,
    navbar,
    loading,
  },
  computed: {
    loading_submit: (state) => state.loadingSubmit,
  },
  data: () => ({
    showPassword_old: false,
    showPassword_new: false,
    passwords: {},
    rules: {
      required: null,
      count: (value) =>
        value.length <= 20 || alertMessage.error["MaxCharacters"],
      count_password: (value) =>
        value.length >= 7 || alertMessage.error["min_password"],
    },
  }),
  methods: {
    check() {
      this.rules = {
        required: (value) => !!value || alertMessage.error["Required"],
      };
    },
    change_password() {
      const validate = this.$refs.change_password.validate();
      if (validate == true) {
        change_password(this.passwords);
      }
    },
  },
};
</script>