import axios from 'axios'
import Store from '@/store/index'
import router from '@/router'
import { create_url_image, toEnglishDigits } from '@/models/changeType'
const token = localStorage.getItem('access_token')

export function getProfile() {
  Store.dispatch('submit', true)
  Store.dispatch('alertError', { show: false })
  Store.dispatch('setUser', {})
  axios.get('/api/Social/Customers/me', {
    headers: {
      'Authorization': `Bearer${token}`
    }
  }).then(Response => {
    Store.dispatch('submit', false)
    const user = {
      nick_name: Response.data.Result.nick_name,
      first_name: Response.data.Result.first_name,
      last_name: Response.data.Result.last_name,
      mobile: Response.data.Result.mobile,
      user_id: Response.data.Result.user_id,
      image: create_url_image(Response.data.Result.image, 150, 150),
      invite: Response.data.Result.invite_code
    }
    Store.dispatch('setUser', user)
  }).catch(() => {
    Store.dispatch('alertError', { show: true, type: 'network' })
  })
}
export function getinviteList() {
  axios.get('/api/Social/Customers/inviteList', {
    headers: {
      'Authorization': `Bearer${token}`
    }
  }).then(Response => {
    if (Response.data.Result.length < 1) {
      Store.dispatch("invite_list", { "islist": false, list: "nothing" })
      return
    }
    Store.dispatch("invite_list", { "islist": true, list: Response.data.Result })
  }).catch(() => {
    Store.dispatch('alertError', { show: true, type: 'network' })
  })
}

export function update_avatar() {
  axios.post('/api/Social/Customers/edit', {
    img: ''
  }, {
    headers: {
      'Authorization': `Bearer${token}`
    }
  }).then(Response => {
    if (Response.data.Result.result) {
      Store.dispatch('remove_image', Response.data.Result.image)
    }
  })
}

export function editProfile(info: any) {
  Store.dispatch('submit', true)
  Store.dispatch('alertSuccess', { show: false })
  Store.dispatch('alertError', { show: false })
  const user = {
    nick_name: info.nick_name,
    first_name: info.first_name,
    last_name: info.last_name,
    mobile: toEnglishDigits(info.mobile),
    user_id: info.user_id,
    image: info.image.split('base64,')[1]
  }
  axios.post('/api/Social/Customers/edit', user, {
    headers: {
      'Authorization': `Bearer${token}`
    }
  }).then(Response => {
    if (Response.data.Result.result == 'success') {
      Store.dispatch('setUser', info)
      Store.dispatch('submit', false)
      Store.dispatch('alertSuccess', { show: true, type: 'update' })
    } else {
      if (Response.data.Result.error_description == "duplicate_nick_name") {
        Store.dispatch('alertError', { show: true, type: 'duplicate_nick_name' })
        setTimeout(() => {
          Store.dispatch('alertError', { show: false, type: 'duplicate_nick_name' })
        }, 2500);
        Store.dispatch('submit', false)
        return
      }
      Store.dispatch('alertError', { show: true, type: 'network' })
      Store.dispatch('submit', false)
    }
  })
}

export function change_password(passwords: {}) {
  Store.dispatch('submit', true)
  Store.dispatch('alertSuccess', { show: false, type: 'update' })
  Store.dispatch('alertError', { show: false, type: 'error_password' })
  axios.post('/api/customers/changePassword', passwords, {
    headers: {
      'Authorization': `Bearer${token}`
    }
  }).then(Response => {
    Store.dispatch('submit', false)
    if (Response.data.Result.Result == 1) {
      Store.dispatch('alertSuccess', { show: true, type: 'update' })
      setTimeout(() => {
        router.push('/profile')
        Store.dispatch('alertSuccess', { show: false, type: 'update' })
      }, 1000);
    } else {
      Store.dispatch('alertError', { show: true, type: 'error_password' })
    }
  })
}
